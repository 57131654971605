@import 'styles.scss';
@import './angular-material-theme.scss';
@import './angular-material-overrides.scss';

// @import 'bootstrap/dist/css/bootstrap.css';
html,
body {
  height: 100%;
}
body {
  margin: 0;
}

p {
  margin-top: 2rem;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  padding-left: 5px;
}

input,
select {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 3.2rem;
  color: $dark;
  border: 1px solid $grey-1;
  background-color: $light-2;
  font-size: 1.5rem;
}

button {
  display: inline-block;
}

.button-div {
  button {
    display: inline-block;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  button:last-child {
    float: right;
  }
}

/* Legacy Styles to be removed when new design is completed for this app */
.btn-dropdown {
  border: 1px solid $grey-1;
  font-size: 1.6rem;
  font-family: $book;
  color: $dark;
  text-align: left;
  height: 3.2rem;
  background-repeat: no-repeat;
  background-position: 97% 50%;
  margin-bottom: 1.2rem;
  background-image: url('#{$cdn-url}/img/16/arrow_down_blue.svg');
  @media #{$ipads} {
    height: 4.4rem;
  }
  &[aria-expanded='true'] {
    border: 1px solid $interaction-blue;
    background-image: url('#{$cdn-url}/img/16/arrow_up_blue.svg');
  }
}

.icon-write {
  background-image: url('#{$cdn-url}/img/20/edit_pen.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  height: 2rem;
  width: 2rem;
}
/* End of legacy code */

//////////////
//Containers//
//////////////
.container {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.service-dialog-container {
  padding: 2rem;
  width: 50rem;

  .flex-row {
    margin-bottom: 1.5rem;
  }

  .flex-row > label {
    margin: 0 0 0 0;
    padding: 0.5rem;
  }
}

.colored-row {
  background-color: $light-1;
}

.spacer {
  flex: 1 1 auto;
}

.mt-6 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
}

div.mat-step-icon {
  width: 50px;
  height: 50px;
}

textarea {
  margin-top: 0.5rem;
  border-radius: 5px;
  font-size: 1.2rem;
}

input {
  border: 1px solid $uiblue-1;
  border-radius: 0.5rem;
  height: 3rem;
}

.sub-header-text {
  margin-top: 0 !important;
}

.actions {
  display: flex;
  margin-left: 0;
}

.clickable {
  cursor: pointer;
}

.btn-margins {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.btn-icon {
  display: flex !important;
  align-items: center;
}

.single-icon {
  color: $uiblue-1;
}

.log-table {
  table-layout: fixed;
}

.responsive-table {
  width: 100%;
  height: 100%;
  border-spacing: 0;
  table-layout: auto;
  border: 1px solid $light-1;
  border-top: 5px solid $uiblue-1 !important;
  tr.mat-mdc-footer-row {
    font-weight: bold;
  }
  tr {
    cursor: pointer;
  }
  th {
    text-align: left;
    font-size: 1.7rem;
    font-weight: bold;
    color: $uiblue-1;
    padding: 0.3rem;
    margin-bottom: 0;
    background-color: $light-3;
    th:last-child {
      word-break: break-all;
    }
  }
  td {
    font-size: 1.6rem !important;
    padding: 0.3rem;
    border: none;
    vertical-align: middle;
    text-align: left;
    line-height: 1.15rem;
    td:last-child {
      overflow-wrap: break-all;
    }
  }
}

.cdk-overlay-pane {
  position: absolute !important;
}

.main-table-header {
  align-items: baseline;
  display: flex;
}

.flex-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
}

.flex-col-5 {
  width: 5%;
}

.flex-col-10 {
  width: 10%;
}

.flex-col-15 {
  width: 15%;
}

.flex-col-20 {
  width: 20%;
}

.flex-col-25 {
  width: 25%;
}

.flex-col-30 {
  width: 30%;
}

.flex-col-33 {
  width: 33%;
}

.flex-col-40 {
  width: 40%;
}

.flex-col-50 {
  width: 50%;
}

.flex-col-100 {
  width: 100%;
}

.ml10 {
  margin-left: 1rem;
}

.ml15 {
  margin-left: 1.5rem;
}

.ml20 {
  margin-left: 2rem;
}

.mr10 {
  margin-right: 1rem;
}

.mr15 {
  margin-right: 1.5rem;
}

.mr20 {
  margin-right: 2rem;
}

.mt10 {
  margin-top: 1rem;
}

.mt15 {
  margin-top: 1.5rem;
}

.mt20 {
  margin-top: 2rem;
}

.mt30 {
  margin-top: 3rem;
}

.mb10 {
  margin-bottom: 1rem;
}

.mb10 {
  margin-bottom: 1.5rem;
}

.mb20 {
  margin-bottom: 2rem;
}

.mb30 {
  margin-bottom: 3rem;
}

// Essentially no limit, because some dropdowns can be very wide.
.btn-dropdown {
  max-width: 90rem;
  margin: 0;
}

.inactive {
  opacity: 0.3;
}

// In desktop view, the dialog should not be full height, and should be scrollable
.dialog-custom-panel {
  max-height: 90vh;
}

@media only screen and (min-width: 780px) and (max-width: 1450px) {
  .responsive-table {
    td {
      font-size: 1.4rem;
    }
  }
}

@media only screen and (min-width: 1030px) and (max-width: 1136px) {
  .mat-elevation-z8 {
    overflow-x: auto;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .container {
    overflow: hidden;
  }

  .client-div {
    display: block;
  }

  .mt-6 img {
    width: 100%;
  }

  .mat-elevation-z8 {
    overflow-x: auto;
  }

  .attest-btn {
    margin-left: 0;
    margin-top: 1rem;
    display: block;
  }

  .responsive-table {
    white-space: nowrap;
    min-width: 450px;
  }

  select {
    display: block;
  }

  .responsive-table {
    border: 1px solid $light-1;
    border-top: 5px solid $uiblue-1;
    height: 100%;
    border-spacing: 0;
    th {
      font-size: 2rem;
      color: $uiblue-1;
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
    }
    td {
      font-size: 1.6rem;
      padding: 0.75rem 1.25rem;
      text-align: center;
      border: none;
    }
  }

  .button-div {
    margin-left: 0;
    button {
      width: 100%;
      margin-left: 0;
    }
    button:last-child {
      float: none;
    }
  }
} // @media only screen

////////////////
//Phone style//
///////////////
@media #{$phones} {
  .dialog-custom-panel {
    min-width: 100%;
  }

  .container {
    overflow: hidden;
  }

  .client-div {
    display: block;
  }

  .mt-6 img {
    width: 100%;
  }

  .mat-elevation-z8 {
    overflow-x: auto;
  }

  .attest-btn {
    margin-left: 0;
    margin-top: 1rem;
    display: block;
  }

  .responsive-table {
    white-space: nowrap;
    min-width: 450px;
  }

  .select {
    display: block;
  }

  .responsive-table {
    border: 1px solid $light-1;
    border-top: 5px solid $uiblue-1;
    height: 100%;
    border-spacing: 0;
    th {
      font-size: 2rem;
      color: $uiblue-1;
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
    }
    td {
      font-size: 1.6rem;
      padding: 0.75rem 1.25rem;
      text-align: center;
      border: none;
    }
  }

  .button-div {
    margin-left: 0;
    button {
      width: 100%;
      margin-left: 0;
    }
    button:last-child {
      float: none;
    }
  }
} // @media #{$phones}
