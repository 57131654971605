@import '_variables.scss';

.mat-mdc-form-field {
  font-size: large !important;
  width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-mdc-checkbox-layout {
  margin-top: 0 !important;;
}

.mat-mdc-dialog-container {
  padding: 0 !important;

  .mat-mdc-dialog-title {
    padding: 0.8rem;
    background-color: $uiblue-3;
    color: $dark-1;
    font-family: $medium;
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0;
    border-bottom: 0.1rem solid $grey-2;
    text-align: left;
  }

  .mat-mdc-dialog-content {
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
    padding: 4rem 4rem 4rem 4rem;
    max-height: 83vh;
    .sub-headline {
      font-size: 1.6rem;
      margin-top: 3rem;
      margin-bottom: 3rem;
      font-weight: bold;
    }
    .dialog-row {
      padding: 1.9rem;
      border-bottom: 0.1rem solid $grey-2;
      display: flex;
      justify-content: space-between;
      .icon-actions {
        display: flex;
        .icon {
          margin-right: 5rem;
          cursor: pointer;
        }
      }
      .mat-mdc-checkbox {
        label {
          margin-top: 0;
        }
      }
    }
  }

  .mat-mdc-dialog-actions {
    margin-bottom: 0;
    margin-top: 4rem;
    justify-content: center;
    padding: 0;
    button {
      margin-right: 4rem;
    }
    button:last-child {
      margin-right: 0rem;
    }
  }
}

.mat-mdc-tooltip {
  font-size: large !important;
}

.mat-mdc-paginator-range-label {
  display: none;
}

.mat-mdc-dialog-title {
  text-align: center;
}

.mat-mdc-dialog-container {
  padding: 0 !important;
  .mat-mdc-dialog-content {
    .confirm-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.mat-stepper-horizontal {
  display: flex !important;
  background-color: #f9f9f9 !important;
  justify-content: center !important;
}

.mat-stepper-horizontal-line {
  border-top: none;
}

.mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header
  .mat-step-label {
  white-space: break-word;
}

.mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:last-child)::after {
  width: calc(50% - 25px) !important;
}

.mat-horizontal-stepper-header {
  // display: unset !important;
  display: flex !important;
}

.mat-mdc-paginator {
  border: 1px solid $light-1;
}

.mat-icon {
  cursor: pointer;
}

.mat-toolbar {
  border: 1px solid $light-1;
  background-color: $white;
}

.mat-elevation-z8 {
  box-shadow: none;
}

.mat-horizontal-stepper-header {
  position: absolute;
}

.mat-stepper-vertical-line::before {
  border-left-width: 0;
}

.mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:first-child)::before {
  content: none !important;
}

.mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:last-child)::after {
  content: none !important;
}

.mat-step-header:hover {
  background-color: #f9f9f9 !important;
}

.mat-mdc-tooltip {
  background-color: white;
  border: 2px solid $uiblue-1;
  color: black !important;
  white-space: pre-line;
}

div.mat-horizontal-stepper-header-container {
  background-color: $light-3;
}

div.mat-horizontal-content-container {
  background-color: $light-3;
}

.mat-mdc-table {
  width: 100%;
  & th {
    padding-bottom: 0.75rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    color: #000 !important;
    border: none !important;
    font-size: 1.4rem;
    text-align: left;
    font-weight: bold;
  }
  & .mat-mdc-header-row {
    background-color: $light-3;
  }
  & td {
    border: none !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  & tr {
    height: 3.1rem !important;
  }
  tr:nth-child(even) {
    background-color: $light-1;
  }
  & .mat-mdc-footer-row {
    font-weight: bold;
    td {
      border-top: 1px solid $light-1 !important;
    }
  }
}

th.mat-mdc-header-cell {
  text-align: center;
}
